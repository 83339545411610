<template>

<div class="intro">

	<div class="intro-text">

		<div v-html="data.text"></div>

	</div>

	<div class="intro-buttons">

		<app-button theme="purple" :text="data.button" v-on:click="onButtonClick(1)" />

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	methods: {

		onButtonClick: function(value) {

			this.$emit('answered', value)

		}

	}

}

</script>

<style scoped>

.intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 684px;
	padding: 40px 20px;
}

.filter-back {
	position: absolute;
	left: 10px;
	top: 0px;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
}

.filter-back:hover span {
	text-decoration: underline;
}

.intro-text {
	color: #fff;
	font-size: 18px;
	text-align: center;
	line-height: 22px;
	margin-bottom: 40px;
}

.intro-text >>> p {
	margin-bottom: 20px;
}

.intro-text >>> *:last-child {
	margin-bottom: 0px;
}

.intro-buttons {
	display: flex;
	flex-direction: row;
}

.intro-buttons >>> .button {
	margin: 0px 10px;
}

</style>
